import axios from 'axios';
import { omit } from 'lodash';
import { update } from '@intercom/messenger-js-sdk';
import { apolloClient } from '@/vue-apollo';
import { AUTH_TOKEN, OWNER, FREE_TIER, STARTER_TIER } from '@/constants';
import { configService } from '@/services/api-config';
import apiService from '@/services/api-service';
import campaignsByCompanyGql from '@/graphql/queries/campaigns/campaignsByCompany.gql';
import conversationsWA from '@/graphql/queries/conversations/whatsappConversation.gql';

export const shared = {
  namespaced: true,
  state: {
    user: {},
    currentCompanyID: null,
    companies: [],
    company: null,
    lang: navigator.language.split('-')[0],
    accessToken: localStorage.getItem(AUTH_TOKEN),
    logged: true,
    sideBarState: 'close',
    roles: [],
    plugins: [],
    showMenuInfo: true,
    currentCompanyConversations: [],
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
    userInfo(state) {
      return state.user;
    },
    getCurrentCompany(state) {
      if (state.company?.id !== state.currentCompanyID) {
        return null;
      }

      return state.company;
    },
    allChannelsByCompany(state, getters) {
      return getters.getCurrentCompany?.campaigns.reduce((obj, { channels }) => [...obj, ...channels], []);
    },
    getCompanies(state) {
      return state.companies;
    },
    getFilterCompanies(state) {
      const { role = '' } = state.user || {};

      return (state.companies?.fullCompanies || state.companies).filter(
        ({ maxUsers }) => OWNER !== role || maxUsers > 0,
      );
    },
    isOwner(state) {
      return state.user.role.includes('owner');
    },
    isFree(state) {
      return FREE_TIER === state.company?.tier?.name;
    },
    isFreeOrStarter(state) {
      return [FREE_TIER, STARTER_TIER].includes(state.company?.tier?.name);
    },
    isSuper(state) {
      return state.user.role.includes('super');
    },
    getLocale(state) {
      return state.lang;
    },
    isLogged(state) {
      return state.logged;
    },
    getUserRole(state) {
      return state.user.role;
    },
    getRoles(state) {
      return state.roles;
    },
    getLanguage(state) {
      return state.lang;
    },
    getPlugins(state) {
      return state.plugins;
    },
    getSideBarState(state) {
      return state.sideBarState;
    },
    getShowMenuInfo(state) {
      return state.showMenuInfo;
    },
    getMaxActiveChannels(state) {
      return Number(state.company?.tier?.settings?.maxChannels || 0);
    },
    getCurrentCompanyConversationsPricing(state) {
      return state.currentCompanyConversations.reduce((total, item) => total + item.pricing, 0);
    },
    getAgents(state) {
      return state.agents;
    }
  },
  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setCurrentCompanyID(state, payload) {
      state.currentCompanyID = payload;
    },
    setCurrentCompany(state, payload) {
      state.company = payload;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setLogged(state, logged) {
      state.logged = logged;
    },
    logout(state) {
      state.companies = [];
    },
    setRoles(state, data) {
      state.roles = data;
    },
    setPlugins(state, data) {
      state.plugins = data;
    },
    setSidebarState(state, data) {
      state.sideBarState = data;
    },
    setShowMenuInfo(state, data) {
      state.showMenuInfo = data;
    },
    setCurrentCompanyConversations(state, data) {
      state.currentCompanyConversations = data;
    }
  },
  actions: {
    async fetchUserInfo({ state, commit }, params) {
      try {
        const { data } = await axios.get(`${configService.apiUrl}users/auth`, {
          headers: {
            Authorization: `Bearer ${params.token || state.accessToken}`,
          },
        });

        if (data?.id && data.roles?.length > 0 && data.permissions && data.permissionsBlocked) {
          const { roles, ...user } = data;
          commit('setUser', {
            ...user,
            role: roles[0],
          });

          if (data.company && data.company !== state.currentCompanyID) {
            commit('setCurrentCompanyID', data.company);
          }

          return true;
        }
        return false;
      } catch {
        return false;
      }
    },
    async fetchCompanyData({ state, commit }) {
      commit('loading/updateCounter', 1, { root: true });
      try {
        const [{ data }, { data: campaigns }] = await Promise.all([
          apiService.fetchCompanyData(state.currentCompanyID),
          apolloClient.query({
            query: campaignsByCompanyGql,
            variables: {
              companyId: state.currentCompanyID,
            },
          }),
        ]).finally(() => {
          commit('loading/updateCounter', -1, { root: true });
        });

        commit('setCurrentCompany', {
          ...data,
          id: data._id,
          tier: {
            ...data.tier,
            id: data.tier?._id,
          },
          campaigns: campaigns.campaignsByCompany.map((campaign) => omit(campaign, ['__typename'])),
        });
        update({
          company: {
            id: data._id,
            name: data.name,
          },
        });
      } catch {
        commit('setCurrentCompanyID', state.companies[0]?.id);
      }
    },
    async fetchCompanyConsumptions({ state, commit }, companyId) {
      commit('loading/updateCounter', 1, { root: true });
      try {
        apolloClient
          .query({
            query: conversationsWA,
            variables: {
              company: companyId ? companyId : state.currentCompanyID,
              date: new Date().toISOString().split('T')[0],
            },
          })
          .then(({ data: { conversationWA } }) => {
            commit('setCurrentCompanyConversations', conversationWA);
          })
          .finally(() => {
            commit('loading/updateCounter', -1, { root: true });
          });
      } catch {
        commit('setCurrentCompanyID', state.companies[0]?.id);
      }
    },
  },
};
