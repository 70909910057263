import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { configuration, configService, token } from './api-config';

const apiService: AxiosInstance = axios.create({
  baseURL: configService.apiUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

apiService.interceptors.request.use(
  (config) => configuration(config),
  (error) => {
    Promise.reject(error.response || error.message);
  },
);

const ApiService = {
  getPayment: (paymentId, queryParams = {}) =>
    apiService.get(`${configService.coreUrl}payment/${paymentId}`, { params: queryParams }),

  getDollarRate: () => apiService.get(`${configService.coreUrl}payment/dollar-rate`),

  getInvoicesUpcoming: (invoiceData) =>
    apiService.post(`${configService.coreUrl}payment/invoices-upcoming`, invoiceData),

  fetchCompanyData: (companyId) => apiService.get(`${configService.apiUrl}company/${companyId}`),

  updateCompany: (id, data) =>
    apiService.post(`${configService.apiUrl}company`, {
      id,
      ...data,
    }),

  updateUserProfile: (userData) => {
    return apiService.put(`${configService.apiUrl}users/profile`, userData);
  },

  manageSubscription: (subscriptionDetails) => {
    const { action, subscriptionId, subscriptionItemId, licensesPrice, newTierId, tierId, cardId, companyId, comment } =
      subscriptionDetails;
    return apiService.post(`${configService.coreUrl}payment/subscriptions`, {
      action,
      subscriptionId,
      subscriptionItemId,
      licensesPrice,
      newTierId,
      tierId,
      cardId,
      companyId,
      comment,
    });
  },

  payNow: ({ paymentId, stripeSourceId }) => {
    return apiService.post(`${configService.coreUrl}payment/invoices/pay`, {
      paymentId,
      stripeSourceId,
    });
  },

  get: (url: string, config?: AxiosRequestConfig) => apiService.get(url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig) => apiService.post(url, data, config),
  put: (url: string, data?: any, config?: AxiosRequestConfig) => apiService.put(url, data, config),
  delete: (url: string, config?: AxiosRequestConfig) => apiService.delete(url, config),
};

export default ApiService;
