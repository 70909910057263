export const nodeDefaultValues = {
  dragHandle: '.vue-flow__node-drag',
  isValidTargetPos: (connection) => /^in-/.test(connection.targetHandle),
  isValidSourcePos: (connection) => /^out-/.test(connection.sourceHandle),
};

export const actions = {
  step: {
    icon: 'carbon:chat',
    value: 'step',
    title: 'chatbot.option.stepTitle',
  },
  trigger: {
    icon: 'material-symbols:line-start-circle-outline-rounded',
    value: 'trigger',
    title: 'chatbot.stepTypes.trigger',
  },
  action: {
    icon: 'mdi:thunder',
    value: 'action',
    title: 'chatbot.stepTypes.action',
  },
  assistant: {
    icon: 'mdi:emoticon-robot',
    value: 'assistant',
    title: 'chatbot.stepTypes.assistant',
  },
  condition: {
    icon: 'mdi:arrow-decision',
    value: 'condition',
    title: 'chatbot.stepTypes.condition',
  },
  loop: {
    icon: 'mdi:loop',
    value: 'loop',
    title: 'chatbot.stepTypes.loop',
  },
};

export const icons = {
  image: 'ph:image',
  video: 'gridicons:video',
  document: 'carbon:document',
  audio: 'iconoir:sound-high',
  sticker: 'ph:smiley-sticker',
};

export const MEDIA_TYPES = {
  AUDIO: {
    AAC: {
      extension: '.aac',
      mimeType: 'audio/aac',
      maxSize: '16MB',
    },
    AMR: {
      extension: '.amr',
      mimeType: 'audio/amr',
      maxSize: '16MB',
    },
    MP3: {
      extension: '.mp3',
      mimeType: 'audio/mpeg',
      maxSize: '16MB',
    },
    MP4_AUDIO: {
      extension: '.m4a',
      mimeType: 'audio/mp4',
      maxSize: '16MB',
    },
    OGG_AUDIO: {
      extension: '.ogg',
      mimeType: 'audio/ogg',
      description: 'OPUS codecs only; base audio/ogg not supported.',
      maxSize: '16MB',
    },
  },
  DOCUMENT: {
    TEXT: {
      extension: '.txt',
      mimeType: 'text/plain',
      maxSize: '100MB',
    },
    CSV: {
      extension: '.csv',
      mimeType: 'text/csv',
      maxSize: '100MB',
    },
    EXCEL_XLS: {
      extension: '.xls',
      mimeType: 'application/vnd.ms-excel',
      maxSize: '100MB',
    },
    EXCEL_XLSX: {
      extension: '.xlsx',
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      maxSize: '100MB',
    },
    WORD_DOC: {
      extension: '.doc',
      mimeType: 'application/msword',
      maxSize: '100MB',
    },
    WORD_DOCX: {
      extension: '.docx',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      maxSize: '100MB',
    },
    POWERPOINT_PPT: {
      extension: '.ppt',
      mimeType: 'application/vnd.ms-powerpoint',
      maxSize: '100MB',
    },
    POWERPOINT_PPTX: {
      extension: '.pptx',
      mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      maxSize: '100MB',
    },
    PDF: {
      extension: '.pdf',
      mimeType: 'application/pdf',
      maxSize: '100MB',
    },
  },
  IMAGE: {
    JPEG: {
      extension: '.jpeg',
      mimeType: 'image/jpeg',
      maxSize: '5MB',
    },
    PNG: {
      extension: '.png',
      mimeType: 'image/png',
      maxSize: '5MB',
    },
  },
  STICKER: {
    ANIMATED_WEBP: {
      extension: '.webp',
      mimeType: 'image/webp',
      maxSize: '500KB',
    },
    STATIC_WEBP: {
      extension: '.webp',
      mimeType: 'image/webp',
      maxSize: '100KB',
    },
  },
  VIDEO: {
    _3GPP: {
      extension: '.3gp',
      mimeType: 'video/3gpp',
      maxSize: '16MB',
    },
    MP4: {
      extension: '.mp4',
      mimeType: 'video/mp4',
      maxSize: '16MB',
    },
  },
};
