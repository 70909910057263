export default (i18n) => {
  return {
    path: '/dashboard',
    redirect: {
      name: 'dashboard-nova-general',
    },
    component: () => import('@/modules/dashboard-nova/views/Dashboard.vue'),
    meta: {
      site: {
        name: i18n.global.t('app.dashboard'),
        t: 'app.dashboard',
      },
    },
    children: [
      {
        path: 'general',
        name: 'dashboard-nova-general',
        component: () => import('@/modules/dashboard-nova/views/partials/General.vue'),
        meta: {
          title: 'dashboard-nova.tabs.general',
          site: {
            t: 'dashboard-nova.tabs.general',
          },
        },
      },
      {
        path: 'communications',
        name: 'dashboard-nova-communications',
        component: () => import('@/modules/dashboard-nova/views/partials/Communications.vue'),
        meta: {
          title: 'dashboard-nova.tabs.communications',
          site: {
            t: 'dashboard-nova.tabs.communications',
          },
        },
      },
      {
        path: 'templates',
        name: 'dashboard-nova-templates',
        component: () => import('@/modules/dashboard-nova/views/partials/Templates.vue'),
        meta: {
          title: 'dashboard-nova.templates.breadcrumb',
          site: {
            t: 'dashboard-nova.templates.breadcrumb',
          },
        },
      },
      {
        path: 'bot',
        name: 'bot',
        component: () => import('@modules/dashboard-nova/views/partials/Bots.vue'),
        meta: {
          priority: 1.4,
          forAuth: true,
          title: 'app.bot',
          site: {
            t: 'app.bot',
          },
          requiredPermissions: [
            'dashboard_bot_conversation_map',
            'dashboard_bot_frequency_table',
          ],
        },
      },
      {
        path: 'surveys',
        name: 'surveys-data',
        component: () => import('@modules/dashboard-nova/views/partials/Polls.vue'),
        meta: {
          priority: 1.5,
          forAuth: true,
          site: {
            t: 'app.surveys',
          },
          title: 'app.surveys',
        },
      },
    ],
  };
};
