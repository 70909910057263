import { i18n } from '@/i18n';

export const AUTH_TOKEN = 'hey-access-token';

const CHANNELS_TYPE = [
  {
    name: 'WhatsApp',
    key: 'whatsapp',
  },
  {
    name: 'Telegram',
    key: 'telegram',
  },
  {
    name: 'Messenger',
    key: 'messenger',
  },
  {
    name: 'Webchat',
    key: 'webchat',
  },
  {
    name: 'Instagram',
    key: 'instagram',
  },
];
const REMOVE = 'REMOVE';
const ADD = 'ADD';
const WHATSAPP = 'whatsapp';
const OWNER_SUPER_PERMISSIONS = ['owner', 'super'];
const SUPER_ADMIN_PERMISSIONS = ['super', 'admin'];
const OWNER = 'owner';
const SYSTEM_ROLES_PERMISSIONS = ['owner', 'super', 'admin'];
const ROLE_ADMIN = 'admin';
const LANGUAGE_ES = 'es';
const LANGUAGE_EN = 'en';
const CONVERSATION_CLIENT_REPORT = 'ConversationClientReport';
const EXCLUDE_REPORTS_TYPES = ['MassiveTemplatesReport', CONVERSATION_CLIENT_REPORT];
const TEMPLATED_STATUS_PAUSED = 'paused';
const TEMPLATED_STATUS_INPROGRESS = 'inprogress';
const STATUS_TO_CANCELED = [TEMPLATED_STATUS_INPROGRESS, 'draft', 'scheduled', TEMPLATED_STATUS_PAUSED];
const ROUTES = [
  {
    path: '/dashboard',
    requiredPermissions: [
      'dashboard_conversation_conversation_average',
      'dashboard_conversation_conversation_total',
      'dashboard_conversation_whatsapp_conversation',
      'dashboard_conversation_total_channel',
      'dashboard_conversation_total_category',
      'dashboard_conversation_total_state',
      'dashboard_conversation_recieved_vs_attended',
      'dashboard_messages_average',
      'dashboard_messages_agent_vs_client',
      'dashboard_messages_density',
      'dashboard_agent_client_average',
      'dashboard_agent_client_category_attended_by_agent',
      'dashboard_agent_client_performance_average_by_agent',
      'dashboard_agent_client_total_conversation_by_agent',
      'dashboard_bot_conversation_map',
      'dashboard_bot_frequency_table',
    ],
  },
  {
    path: '/send-templates',
    requiredPermissions: ['templates_send'],
  },
  {
    path: '/templates',
    requiredPermissions: ['templates_send'],
  },
  {
    path: '/channels',
    requiredPermissions: [
      'channels_read',
      'channels_write',
      'channels_assign_channel',
      'templates_write_text',
      'templates_write_document',
      'templates_write_image',
      'templates_write_video',
      'templates_read',
    ],
  },
  {
    path: '/campaigns',
    requiredPermissions: [
      'campaigns_read',
      'campaigns_write',
      'campaigns_blocked_clients',
      'config_campaign_channels_read',
      'config_campaign_channels_write',
      'config_campaign_assigned_admin_read',
      'config_campaign_assigned_admin_write',
      'config_campaign_assigned_agent_read',
      'config_campaign_assigned_agent_write',
      'config_campaign_categories_write',
      'config_campaign_categories_read',
      'config_campaign_labels_read',
      'config_campaign_labels_write',
      'config_campaign_automatic_messages_read',
      'config_campaign_automatic_messages_write',
      'config_campaign_schedules_write',
      'config_campaign_schedules_read',
      'config_campaign_options_read',
      'config_campaign_options_write',
      'config_campaign_options_bots',
      'config_campaign_options_transfer_agent',
      'config_campaign_options_message_multimedia',
      'config_campaign_options_transfer_campaigns',
      'config_campaign_fast_text_write',
      'config_campaign_fast_image_write',
      'config_campaign_fast_location_read',
      'config_campaign_fast_location_write',
      'config_campaign_fast_video_read',
      'config_campaign_fast_video_write',
      'config_campaign_fast_templates',
      'config_campaign_fast_text_read',
      'config_campaign_fast_image_read',
    ],
  },
  {
    path: '/settings/users',
    requiredPermissions: ['users_read', 'users_write', 'users_write'],
  },
  {
    path: '/messages',
    requiredPermissions: ['messages_change_status', 'messages_read', 'messages_report'],
  },
  {
    path: '/team',
    requiredPermissions: ['team_info_card', 'team_read', 'team_disconnect_user'],
  },
  {
    path: '/reports',
    requiredPermissions: ['reports_generate', 'reports_programmer'],
  },
];
const FORMAT_DATE_TIME = 'YYYY-MM-DD';
const DEFAULT_TOTAL = 999;
const STATUS_COMPANY_ACTIVE = 'active';
const STATUS_COMPANY_PAST_DUE = 'past_due';
const STATUS_COMPANY_DELINQUENT = 'delinquent';
const TAX_AMOUNT = 16;

const WHATSAPP_SCOPE = [
  'public_profile',
  'business_management',
  'whatsapp_business_management',
  'whatsapp_business_messaging',
];

const WHATSAPP_EXTRA = { feature: 'whatsapp_embedded_signup' };

const WHATSAPP_RESPONSE_TYPES = ['code token'];
const COLORS_CATEGORY = {
  service: 'gray',
  marketing: 'purple',
  authentication: 'green',
  utility: 'cian',
  user_initiated: 'gray',
  business_initiated: 'purple',
  default: 'gray',
};
const TEMPLATE_STATUS_COLORS = {
  pending: 'yellow',
  error: 'alert',
  failed: 'alert',
  canceled: 'alert',
  delivered: 'alert-green',
  processed: 'alert-green',
  submitted: 'alert-green',
  read: 'alert-green',
};
const DEFAULT_LABELS = ['Nombre', 'Dirección', 'Teléfono', 'Celular', 'Otro'];
const DRAFT = 'draft';
const SCHEDULED = 'scheduled';
const INPROGRESS = 'inprogress';
const PENDING = 'pending';

const STATUS_LABELS = {
  succeeded: 'configuration.status.succeeded',
  pending: 'configuration.status.pending',
  failed: 'configuration.status.failed',
};

const DESCRIPTION_LABELS = {
  monthlyRenewal: 'configuration.description-billing.monthlyRenewal',
  addBalance: 'configuration.description-billing.addBalance',
  updateLicenses: 'configuration.description-billing.updateLicenses',
  paymentFailed: 'configuration.description-billing.paymentFailed',
};

const TAX_REGIMES = [
  {
    name: i18n.global.t('tax_regimes.general_legal_person'),
    value: 'general_legal_person',
  },
  {
    name: i18n.global.t('tax_regimes.legal_entities_non_profit'),
    value: 'legal_entities_non_profit',
  },
  {
    name: i18n.global.t('tax_regimes.resident_abroad'),
    value: 'resident_abroad',
  },
  {
    name: i18n.global.t(
      'tax_regimes.production_cooperative_societies'
    ),
    value: 'production_cooperative_societies',
  },
  {
    name: i18n.global.t('tax_regimes.agricultural_livestock'),
    value: 'agricultural_livestock',
  },
  {
    name: i18n.global.t('tax_regimes.optional_group_of_companies'),
    value: 'optional_group_of_companies',
  },
  {
    name: i18n.global.t('tax_regimes.coordinated'),
    value: 'coordinated',
  },
  {
    name: i18n.global.t('tax_regimes.simplified_trust'),
    value: 'simplified_trust',
  },
  {
    name: i18n.global.t('tax_regimes.wages_salaries_income'),
    value: 'wages_salaries_income',
  },
  { name: i18n.global.t('tax_regimes.lease'), value: 'lease' },
  {
    name: i18n.global.t(
      'tax_regimes.property_disposal_acquisition'
    ),
    value: 'property_disposal_acquisition',
  },
  {
    name: i18n.global.t('tax_regimes.other_income'),
    value: 'other_income',
  },
  {
    name: i18n.global.t('tax_regimes.dividend_income'),
    value: 'divident_income',
  },
  {
    name: i18n.global.t(
      'tax_regimes.individual_business_professional'
    ),
    value: 'individual_business_professional',
  },
  {
    name: i18n.global.t('tax_regimes.interest_income'),
    value: 'interest_income',
  },
  {
    name: i18n.global.t('tax_regimes.income_obtaining_price'),
    value: 'income_obtaining_price',
  },
  {
    name: i18n.global.t('tax_regimes.no_tax_obligation'),
    value: 'no_tax_obligation',
  },
  {
    name: i18n.global.t('tax_regimes.tax_incorporation'),
    value: 'tax_incorporation',
  },
  {
    name: i18n.global.t(
      'tax_regimes.income_through_technology_platform'
    ),
    value: 'income_through_technology_platform',
  },
];

const DEFAULT_COUNTRY = 'MEX';

const CFDI_USAGE = [
  { name: 'Adquisición de Mercancía', value: 'acquisition_of_merchandise' },
  {
    name: 'Devolución, Descuento, Bonificación',
    value: 'return_discount_bonus',
  },
  { name: 'Gastos Generales', value: 'general_expense' },
  { name: 'Edificios', value: 'buildings' },
  {
    name: 'Mobiliario y Equipo de Oficina',
    value: 'furniture_office_equipment',
  },
  { name: 'Equipo de Transporte', value: 'transport_equipment' },
  { name: 'Equipo de Computación', value: 'computer_equipment' },
  { name: 'Herramientas y Moldes', value: 'dye_molds_tools' },
  { name: 'Comunicación Telefónica', value: 'telephone_communication' },
  { name: 'Comunicación Satelital', value: 'satellite_communication' },
  { name: 'Otro Equipo y Maquinaria', value: 'other_machinery_equipment' },
  { name: 'Gastos Hospitalarios', value: 'hospital_expense' },
  {
    name: 'Gastos Médicos por Incapacidad',
    value: 'medical_expense_disability',
  },
  { name: 'Gastos Funerarios', value: 'funeral_expense' },
  { name: 'Donación', value: 'donation' },
  {
    name: 'Intereses de Préstamos Hipotecarios',
    value: 'interest_mortage_loans',
  },
  { name: 'Contribución al SAR', value: 'contribution_sar' },
  {
    name: 'Prima de Seguro Médico',
    value: 'medical_expense_insurance_premium',
  },
  {
    name: 'Gastos de Transporte Escolar',
    value: 'school_transportation_expense',
  },
  { name: 'Depósito en Cuenta de Ahorro', value: 'deposit_saving_account' },
  {
    name: 'Pago de Servicios Educativos',
    value: 'payment_educational_service',
  },
  { name: 'Sin Efecto Fiscal', value: 'no_tax_effect' },
  { name: 'Pago', value: 'payment' },
  { name: 'Nómina', value: 'payroll' },
];

const TAX_ID_TYPES = ['EIN', 'SSN'];

const PERMISSIONS_TEMPLATE_TYPES = {
  templates_write_text: 'text',
  templates_write_image: 'image',
  templates_write_video: 'video',
  templates_write_document: 'document',
};

const IGNORE_LOADER_QUERY = [
  'totalsUserStatus',
  'conversationsByChannel',
  'conversationsReceivedVsAttended',
  'conversationsByCategory',
  'conversationsStatuses',
  'conversationWA',
  'conversationsDataCards',
  'messagesDataCards',
  'questionByPoll',
  'questionsAnswered',
  'agentsKpi',
  'clientsDataCards',
];
const TIERS_ALLOWED_CLOSE_AND_RESIGNED = ['BUSINESS', 'ENTERPRISE', 'STARTER', 'PRO'];

const AVAILABLE_CHANNELS_URLS = ['whatsapp', 'telegram', 'messenger'];

export {
  CHANNELS_TYPE,
  REMOVE,
  ADD,
  WHATSAPP,
  OWNER_SUPER_PERMISSIONS,
  OWNER,
  SYSTEM_ROLES_PERMISSIONS,
  ROUTES,
  ROLE_ADMIN,
  WHATSAPP_SCOPE,
  WHATSAPP_EXTRA,
  LANGUAGE_EN,
  LANGUAGE_ES,
  CONVERSATION_CLIENT_REPORT,
  FORMAT_DATE_TIME,
  DEFAULT_TOTAL,
  WHATSAPP_RESPONSE_TYPES,
  COLORS_CATEGORY,
  TEMPLATE_STATUS_COLORS,
  DEFAULT_LABELS,
  DRAFT,
  SCHEDULED,
  INPROGRESS,
  STATUS_TO_CANCELED,
  TEMPLATED_STATUS_PAUSED,
  TEMPLATED_STATUS_INPROGRESS,
  PENDING,
  STATUS_LABELS,
  DESCRIPTION_LABELS,
  TAX_REGIMES,
  CFDI_USAGE,
  DEFAULT_COUNTRY,
  TAX_ID_TYPES,
  PERMISSIONS_TEMPLATE_TYPES,
  IGNORE_LOADER_QUERY,
  TIERS_ALLOWED_CLOSE_AND_RESIGNED,
  EXCLUDE_REPORTS_TYPES,
  SUPER_ADMIN_PERMISSIONS,
  STATUS_COMPANY_ACTIVE,
  STATUS_COMPANY_PAST_DUE,
  STATUS_COMPANY_DELINQUENT,
  TAX_AMOUNT,
  AVAILABLE_CHANNELS_URLS,
};

export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_DOCUMENTS = 'document';
export const MEDIA_TYPE_VIDEO = 'video';
export const MEDIA_TYPE_LOCATION = 'location';
export const MEDIA_TYPE_DOCUMENT = 'document';
export const TEMPLATE_TYPES = [
  {
    id: 1,
    type: 'common.text',
    key: 'text',
    permission: 'templates_write_text',
  },
  {
    id: 2,
    type: 'campaigns.data-bank.templates.image',
    key: 'image',
    permission: 'templates_write_image',
  },
  {
    id: 3,
    type: 'campaigns.data-bank.templates.video',
    key: 'video',
    permission: 'templates_write_video',
  },
  {
    id: 4,
    type: 'campaigns.data-bank.templates.document',
    key: 'document',
    permission: 'templates_write_document',
  },
];

export const FREE_TIER = 'FREE';
export const STARTER_TIER = 'STARTER';
export const PRO_TIER = 'PRO';
export const ENTERPRISE_TIER = 'ENTERPRISE';
export const ROLE_AGENT = 'agent';



export const NUM_FORMATS = {
  AMOUNT_WITH_COMAS: '0,00',
  DECIMAL_TWO_DIGITS: '0.0',
  PERCENTAGE: '0%',
  CURRENCY: '$ 0.00'
};