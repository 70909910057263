import moment from 'moment-timezone';

export const filters = {
  namespaced: true,
  strict: false,
  state: {
    global: {
      companyId: null,
      campaignId: null,
      channelId: null,
      startDate: moment().format(),
      endDate: moment().format(),
    },
  },
  getters: {
    getFilters(state) {
      return state.global;
    },
    hasFilters(state) {
      return Object.values(state.global).some((value) => value);
    }
  },
  mutations: {
    setFilters(state, data) {
      if (data.key) {
        state.global[data.key] = data.value;
        return;
      }
      state.global = data.value;
    },
    reloadFilters(state) {
      state.global = {
        companyId: null,
        campaignId: null,
        channelId: null,
        startDate: moment().startOf('day').format(),
        endDate: moment().startOf('day').format(),
      };
    }
  },
};
