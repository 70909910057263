import { createRouter, createWebHistory } from 'vue-router';
import { update } from '@intercom/messenger-js-sdk';
import { getToken, onLogout } from '@/vue-apollo';
import store from '@/store/index';
import { i18n } from '@/i18n';
import { OWNER_SUPER_PERMISSIONS, SYSTEM_ROLES_PERMISSIONS } from '@/constants';
import { apolloClient } from '@/vue-apollo';

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      forVisitors: true,
    },
    alias: '/login',
  },
  {
    path: '/home',
    name: 'main',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/my-profile',
        name: 'my-profile',
        component: () => import('../views/Profiles/MyProfile.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            t: 'header.user-menu.profile',
          },
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('../views/Notification.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
          site: {
            name: i18n.global.t('app.notifications'),
          },
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('@/views/PageNotFound.vue'),
        meta: {
          site: {
            name: '404',
          },
        },
      },
    ],
  },
  {
    path: '/facebook',
    component: () => import('../views/LoginFBSuccessfully.vue'),
    meta: {
      forAuth: false,
      layout: 'without-layout',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function hasPermissions(user, requiredPermissions) {
  return (
    SYSTEM_ROLES_PERMISSIONS.includes(user.role) ||
    requiredPermissions.some((permission) => user.permissions.includes(permission))
  );
}

router.beforeEach(async (to, from, next) => {
  if (to.name === 'login' && to.query.data) {
    await onLogout(apolloClient);
  }

  const isAuthenticated = store.getters['shared/getAccessToken'];
  const token = !!getToken();

  if (to.matched.some((record) => record.meta.forVisitors) && isAuthenticated && token) {
    next('/dashboard');
    return;
  }
  if (to.matched.some((record) => record.meta.forAuth) && (!isAuthenticated || !token)) {
    next('/');
    return;
  }

  const user = store.getters['shared/userInfo'];
  const { requiredPermissions } = to.meta;

  if (['profiles', 'profiles/add'].includes(to.name) && !OWNER_SUPER_PERMISSIONS.includes(user.role)) {
    next('not-found');
    return;
  }
  if (['company'].includes(to.name) && !['owner'].includes(user.role)) {
    next('not-found');
    return;
  }
  if (requiredPermissions && !hasPermissions(user, requiredPermissions)) {
    next('not-found');
    return;
  }

  const originalGetRoutes = router.getRoutes.bind(router);

  router.getRoutes = () => getSortedRoutes();

  const getSortedRoutes = () => {
    return originalGetRoutes().sort((a, b) => {
      if (a.meta.priority === undefined || a.meta.priority > b.meta.priority) {
        return 1;
      }

      if (b.meta.priority === undefined || a.meta.priority < b.meta.priority) {
        return -1;
      }

      return 0;
    });
  };

  const hasCanceledSubscription = store.getters['shared/getCurrentCompany']?.configuration?.schedule?.type === 'cancel';
  const companyRoutes = ['company-config', 'company-payment-methods', 'company-balance'];
  if (companyRoutes.includes(to.name) && hasCanceledSubscription) {
    next({ name: 'company-data' });
  }

  next();
});

router.afterEach(() => {
  update({});
});

export default router;
